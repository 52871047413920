import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Box, Section, Image, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkımızda | Lux Vorux'u Keşfedin
			</title>
			<meta name={"description"} content={"İçinizdeki Potansiyeli Ortaya Çıkarın"} />
			<meta property={"og:title"} content={"Hakkımızda | Lux Vorux'u Keşfedin"} />
			<meta property={"og:description"} content={"İçinizdeki Potansiyeli Ortaya Çıkarın"} />
			<meta property={"og:image"} content={"https://luxvorux.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://luxvorux.com/img/9034254.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://luxvorux.com/img/9034254.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://luxvorux.com/img/9034254.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://luxvorux.com/img/9034254.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://luxvorux.com/img/9034254.png"} />
			<meta name={"msapplication-TileImage"} content={"https://luxvorux.com/img/9034254.png"} />
		</Helmet>
		<Components.Header />
		<Section background="url(https://luxvorux.com/img/3.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				max-width="670px"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
				Hakkımızda
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
				Lux Vorux'da bir spor salonundan daha fazlasıyız - büyüme, güç ve topluluk için bir sığınağız. Basit ama derin bir fikirden doğduk: herkes için ilerlemeyi ve refahı besleyen bir fitness ortamı yaratmak. Gereksiz olanı bir kenara bıraktık ve gerçekten önemli olana odaklandık - daha sağlıklı, daha güçlü bir size doğru yolculuğunuz. Hikayemiz taşa yazılmadı, kapılarımızdan giren her üyemizle, ulaşılan her hedefle ve üstesinden gelinen her zorlukla gelişiyor.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Fitness Deneyimleri Yaratmak
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Bağlılığımız ağırlıkların ve makinelerin ötesine uzanıyor. Motive eden, ilham veren ve destekleyen bir deneyim yaratmakla ilgilidir. Lux Vorux'a adım attığınız andan itibaren farkı hissedeceksiniz. Havada, personelimizin sıcak selamlarında ve spor salonu müdavimlerinin cesaretlendirici gülümsemelerinde. Biz sadece fiziksel zindelikle ilgili değiliz - pozitiflik, dayanıklılık ve azimle gelişen bir zihniyeti beslemekle ilgiliyiz.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://luxvorux.com/img/4.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://luxvorux.com/img/5.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 10px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Lux Vorux Neden Öne Çıkıyor?
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					<Strong>Gözdağı Vermeden Uzman Rehberliği:</Strong> Eğitmenlerimiz göz korkutmak için değil, destek olmak için buradalar. Fitness hedeflerinize giden yolu çizerken sizin ortağınızdırlar.
					<br /><br />
					<Strong>Egzersizin Ötesinde Katılım:</Strong> Aranızdaki bağları güçlendiren ve sağlıklı yaşam yolculuğunuzu zenginleştiren atölye çalışmalarına, topluluk etkinliklerine ve grup oturumlarına katılın.
					<br /><br />
					<Strong>Sürdürülebilir Uygulamalar:</Strong> Bedenimize gösterdiğimiz özeni gezegenimize de göstermemiz gerektiğine inanıyoruz. Spor salonumuz, kalite veya deneyimden ödün vermeden çevre dostu uygulamaları benimser.

				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Dönüştürücü Bir Yolculuğa Çıkın
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Lux Vorux'da sizin hikayeniz bizim hikayemizdir. Sizi kendi güç, dayanıklılık ve topluluk hikayenizi bizimle birlikte oluşturmaya davet ediyoruz. Daha büyük bir şeyin parçası olun - kişisel başarı ve kolektif refaha yönelik bir hareket. Hayallerinizi birlikte gerçeğe dönüştürelim.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://luxvorux.com/img/6.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});